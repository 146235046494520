import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import { Button, GlobalStyle, Input, Stack, Text } from '@procsea/design-system'

import { EMAIL_REGEX } from 'src/constants/constants'
import { useAuth } from 'src/contexts/authContext'
import useDocumentTitle from 'src/hooks/useDocumentTitle'
import lock from 'src/images/lock.svg'
import { ForgotPasswordField, ForgotPasswordForm, LocaleParams } from 'src/types'

import { AuthContainer, AuthMainCard, AuthMainCardImage, StyledRouterLink } from '../common'
import AuthHeader from '../common/AuthHeader'

const ForgotPassword = () => {
  useDocumentTitle(gettext('Reset Password'))

  const auth = useAuth()
  const { control, errors, handleSubmit } = useForm<ForgotPasswordForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const history = useHistory()
  const { locale } = useParams<LocaleParams>()
  const [isLoading, setIsLoading] = useState(false)
  const [apiError, setApiError] = useState<string>()

  const onSubmit = async (form: ForgotPasswordForm) => {
    setIsLoading(true)
    const response = await auth.forgotPassword({
      email: form[ForgotPasswordField.EMAIL],
    })
    if (!response.ok) {
      const { user_message: userMessage, validation_errors: validationErrors } =
        await response.json()
      setApiError(validationErrors?.email || userMessage)
    } else {
      history.push(`/${locale}/accounts/password_reset/done`)
    }
    setIsLoading(false)
  }

  return (
    <AuthContainer>
      <GlobalStyle styledTheme="light" />

      <AuthHeader data-e2e="forgot-password-home-link" />

      <AuthMainCard shadow="middle" spacing="xlarge">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="large">
            <AuthMainCardImage src={lock} />

            <Text size="xxxlarge" variant="secondary">
              {gettext('Forgot password?')}
            </Text>

            <Controller
              control={control}
              defaultValue=""
              name={ForgotPasswordField.EMAIL}
              render={({ onBlur, onChange, value: controllerValue }) => (
                <Input
                  fluid
                  data-e2e="forgot-password-email-input"
                  disabled={isLoading}
                  errorMessage={errors[ForgotPasswordField.EMAIL]?.message}
                  hasError={!!errors[ForgotPasswordField.EMAIL]}
                  label={gettext('Email')}
                  name={ForgotPasswordField.EMAIL}
                  onBlur={onBlur}
                  onChange={({ value }: { value: string }) => {
                    onChange(value)
                    setApiError(undefined)
                  }}
                  type="email"
                  value={controllerValue}
                />
              )}
              rules={{
                pattern: {
                  message: gettext('Invalid email'),
                  value: EMAIL_REGEX,
                },
                required: gettext('This field is required'),
              }}
            />

            {!!apiError && (
              <Text data-e2e="forgot-password-error-text" variant="danger">
                {apiError}
              </Text>
            )}

            <Button
              data-e2e="forgot-password-submit-button"
              disabled={isLoading}
              type="submit"
              variant="primary"
            >
              {gettext('Reset password')}
            </Button>

            <StyledRouterLink
              data-e2e="forgot-password-login-link"
              to={`/${locale}/accounts/login`}
            >
              {gettext('Back to login page')}
            </StyledRouterLink>
          </Stack>
        </form>
      </AuthMainCard>
    </AuthContainer>
  )
}

export default ForgotPassword
